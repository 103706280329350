@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Jackular";
  src:
    url("/marketplace/fonts/Formular.eot") format("embedded-opentype"),
    url("/marketplace/fonts/Formular.woff") format("woff"),
    url("/marketplace/fonts/Formular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Jackular";
  src:
    url("/marketplace/fonts/Formular-Medium.eot") format("embedded-opentype"),
    url("/marketplace/fonts/Formular-Medium.woff") format("woff"),
    url("/marketplace/fonts/Formular-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "VC Garamond Condensed";
  src:
    url("/marketplace/fonts/VCGaramondCondensed-Regular.woff") format("woff"),
    url("/marketplace/fonts/VCGaramondCondensed-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "K-Suisse";
  src: url("/marketplace/fonts/SuisseIntl-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "K-Suisse";
  src: url("/marketplace/fonts/SuisseIntl-Book.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Sohne";
  src: url("/marketplace/fonts/SohneBreit-Kraftig.ttf");
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    "K-Suisse",
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.tnr {
  font-family: "Times New Roman";
}

html.dark {
  background: #171717;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

.w-22 {
  width: 88px;
}

.alternate-font {
  font-family: "Times new roman";
}

.asset-card:hover .buy-now-hidden {
  visibility: visible;
  opacity: 1;
  height: 40px;
}

.asset-card:hover .generator {
  visibility: visible;
  opacity: 1;
}

.asset-card:hover .contained-asset {
  scale: 1.05;
}

.asset-card:hover .generator:hover .gb {
  color: black;
}

.full-h {
  /* minus header height */
  height: calc(100vh - 80px);
}

.flat-color {
  opacity: 1 !important;
}

.carousel-pics {
  width: 24%;
}

@media only screen and (min-width: 600px) {
  .carousel-pics {
    width: 12%;
  }
}

.anim-loading {
  animation: shimmer 2s infinite linear;
  background-size: 1000px 100%;
}

.progress-bg {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
    to right,
    rgb(31 205 95) 4%,
    rgb(34 197 94) 25%,
    rgb(47 206 106) 36%
  );
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.header {
  background: white;
  border-bottom: 1px solid rgb(229, 231, 239);
}

.header-warm {
  background: #f8f6f4;
  border-bottom: 1px solid #f8f6f4;
}

.header-warm #search {
  background: #eee9e4;
}

.header #search {
  background: rgb(245, 245, 245);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.infinite-scroll-component::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.infinite-scroll-component {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.depth-bar:hover > #floorDepthTooltip {
  display: block;
  visibility: visible;
  opacity: 1;
}

.depth-bar:hover .bar {
  background: black !important;
}

.dark .depth-bar:hover .bar {
  background: white !important;
}

.depth-bar:hover .hover-bar {
  opacity: 1;
}

.bar {
  opacity: 1;
}

.tool-tip-small-font {
  font-size: 10px !important;
}

.asset-image-container:hover > .asset-image {
  transform: scale(1.06);
}

.theader {
  color: white !important;
}

.hover-copy:hover .hover-tooltip {
  visibility: visible !important;
  opacity: 1 !important;
}

.hover-copy:hover > svg {
  opacity: 0.7;
}

.offer-item:hover .slide-over {
  width: 70px;
}

.dropcard-gradient {
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 73%) 0%,
    rgb(0 0 0 / 36%) 45.05%,
    rgba(0, 0, 0, 0) 64.37%,
    rgb(0 0 0 / 42%) 112.34%
  );
}

#heroTextSpin:after {
  content: "Fidenza";
  animation: textSpin 20s linear infinite;
}

@keyframes textSpin {
  0% {
    content: "Fidenza";
  }
  10% {
    content: "Chromie Squiggles";
  }
  20% {
    content: "Gazers";
  }
  30% {
    content: "Meridian";
  }
  40% {
    content: "Fontana";
  }
  50% {
    content: "Factura";
  }
  60% {
    content: "Archetype";
  }
  70% {
    content: "Anticyclone";
  }
  80% {
    content: "Screens";
  }
  90% {
    content: "Memories of Qilin";
  }
}

.swiper-button-next,
.swiper-button-prev {
  background: rgba(0, 0, 0, 0.5) !important;
  height: 44px !important;
  width: 44px !important;
  border-radius: 100% !important;
}

.swiper-button-prev {
  padding-right: 3px !important;
}

.swiper-button-next {
  padding-left: 3px !important;
}

.swiper-button-next:after {
  font-size: 21px !important;
  color: white !important;
}

.swiper-button-prev:after {
  font-size: 21px !important;
  color: white !important;
}

.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper {
  padding-bottom: 20px;
}

.drop-card:hover .drop-card__actions {
  bottom: 0px;
}

#collectionCard:hover .ribbonBadge {
  display: block;
  opacity: 1;
}

.ls-display {
  letter-spacing: -4%;
}

.ls-header {
  letter-spacing: -2%;
}

.ls-p {
  letter-spacing: 0;
}

:root {
  --color-foreground: 0 0 0;
  --color-foreground-muted: 115 115 115;
  --background-color: 255 255 255;

  &.dark {
    --color-foreground: 255 255 255;
    --color-foreground-muted: 163 163 163;
    --background-color: 23 23 23;
  }
}

@layer components {
  .text-secondary {
    @apply text-neutral-600 dark:text-neutral-400;
  }
}

.background-blur:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}
/* Markdown styling */

.markdown > h1,
.markdown > h2,
.markdown > h3,
.markdown > h4,
.markdown > h5,
.markdown > h6 {
  font-weight: 500;
  font-size: 16px;
}

.markdown strong {
  font-weight: 500;
  text-decoration: underline;
  text-decoration-style: dotted;
}

[data-radix-popper-content-wrapper] {
  pointer-events: none;
}
